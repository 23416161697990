import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import { BrowserRouter, Route } from "react-router-dom"


class ProjectSlider extends Component{

    constructor(props) {
        super(props)
        this.goNext = this.goNext.bind(this)
        this.goPrev = this.goPrev.bind(this)
        this.swiper = null
      }
    
      goNext() {
        if (this.swiper) this.swiper.slideNext()
      }
    
      goPrev() {
        if (this.swiper) this.swiper.slidePrev()
      }

      makeHref(){ return 'http://topnotchcarpentry.us'; }
    
    render(){
        var link = <button href={this.makeHref('Top Notch Carpentry')}></button>;
        
        return <div> {link}</div>;
    }
    
      
    render(){
        var link = <a href={this.makeHref('Top Notch Carpentry')}></a>;

        const params = {
            slidesPerView : 1,
            loop: true,
            speed: 1000,
            spaceBetween: 30
        
        };
        
        let data = [
            {img: 'ipq-topnotch.png', sliderTitle: 'Top Notch Carpentry', sliderShortDesc: `Top Notch Carpentry is a construction company in Austin Tx. We offer Top Notch Carpentry our full range of services, these include invoices, proposals, and the implementation of their company website.`, sliderLink: 'https://www.topnotchcarpentry.us', title:"Top Notch Carpentry", name:" Top Notch"},
            // {img: 'ipq-desincal.png', sliderTitle: 'Company Website', sliderShortDesc: 'Petro-Arkanum is an ', sliderLink: 'project-details'},
            {img: 'ipq-chajinel.png', sliderTitle: 'Chajinel', sliderShortDesc: 'Chajinel is an elderly services company that provides living assistance to seniors in the San Francisco bay area.', sliderLink: 'https://www.chajinel.com', title:"Chajinel", name:" Chajinel"},
        ];


        let DataList = data.map((val, i)=>{

            return(
                <div className="swiper-slide latest-project-slider__single-slide" key={i}>
                    <div className="row row-30 align-items-center">
                        <div className="col-lg-6">
                            <div className="image">
                                <img src={`assets/img/projects/${val.img}`} className="img-fluid" alt="" />
                                {link}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="content">
                                <h3 className="count">{'0'+(i+1)}</h3>
                                <h2 className="title">{val.sliderTitle}</h2>
                                <p className="desc">{val.sliderShortDesc}</p>
                                {/* <a href={`${process.env.PUBLIC_URL}/${val.sliderLink}`} className="see-more-link see-more-link--color">VIEW MORE</a> */}
                            </div>
                        </div>
                    </div>
                    <div className="off-canvas-widget-social">
                        <a href={val.sliderLink} title={val.title} target="_blank" rel = "noopener noreferrer">Visit {val.name} Site <i className="fa fa-globe" /></a>
                    </div>
                </div>
            )
        });

        return(
            <div>
               {/*====================  project slider area ====================*/}
                <div className="project-slider-area grey-bg section-space--inner--120">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        {/* section title */}
                        <div className="section-title-area text-center">
                            <h2 className="section-title section-space--bottom--50">Our Latest Projects</h2>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="latest-project-slider">
                            <div className="latest-project-slider__container-area" style={{width:"90%", margin:"0 auto"}}>
                                <Swiper {...params} ref={node => { if (node) this.swiper = node.swiper }}>
                                    {DataList}
                                </Swiper>
                            
                                {/* <button className="ht-swiper-button-prev ht-swiper-button-nav" onClick={this.goPrev}><i className="ion-ios-arrow-left" /></button>
                                <button className="ht-swiper-button-next ht-swiper-button-nav" onClick={this.goNext}><i className="ion-ios-arrow-forward" /></button> */}
                            </div>
                        </div>
                        <button className="ht-swiper-button-prev ht-swiper-button-nav" onClick={this.goPrev}><i className="ion-ios-arrow-left" /></button>
                        <button className="ht-swiper-button-next ht-swiper-button-nav" onClick={this.goNext}><i className="ion-ios-arrow-forward" /></button>
                    </div>
                    </div>
                </div>
            </div>
            {/*====================  End of project slider area  ====================*/}
            </div>
        )
    }
}


export default ProjectSlider;