import React, { Component } from 'react';
import ModalVideo from 'react-modal-video'
import video from "../../public/assets/clips/ipq-video3.mp4"

// let lastScrollY = 0;
// let ticking = false;
class VideoCta extends Component{
    constructor(props) {
        super(props);
        this.state = {
            width: 0, 
            height: 0, 
            isPlaying:false,
            pressedPause:false,
        };
      }
        componentDidMount() {
            window.addEventListener('scroll', this.handleScroll, false);
            window.addEventListener('resize', this.updateWindowDimensions);
            }


      componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.updateWindowDimensions);
      }

      handleScroll = e => {
        e.preventDefault();
        const topDistance = window.pageYOffset
        if (topDistance > 1900 && topDistance < 2200) {
            if(!this.state.pressedPause){
                this.refs.ipqVideo.play();
                this.setState({isPlaying:true})
            }
        }else{
            this.refs.ipqVideo.pause();
            this.setState({isPlaying:false})
        }
      }
       
      playVideo = () => {
        // You can use the play method as normal on your video ref
        const { isPlaying } = this.state
        this.refs.ipqVideo.play();
        if (isPlaying){
            this.refs.ipqVideo.pause();
            this.setState({isPlaying:false, pressedPause:true})
        }
        else if (!isPlaying){
            this.refs.ipqVideo.play();
            this.setState({isPlaying:true})
        }
      };
     
    render(){
        return(
            <div>
                {/*====================  IPQ Video Area ====================*/}
                <div className="video-cta-area section-space--inner--120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className="video-cta-content">
                                <h4 className="video-cta-content__small-title">About Us</h4>
                                <h3 className="video-cta-content__title">We Make Your Life Easier</h3>
                                <p className="video-cta-content__text">IPQ LLC, is a company located in Austin Texas formed with the vision of helping businesses like yours, to process and automate technical work such as writing proposals, sending invoices, and development of web sites as well as web services. </p>
                                <a href={`${process.env.PUBLIC_URL}/contact-us`} className="ht-btn ht-btn--round">CONTACT US</a>
                                </div>
                            </div>
                        <div className="col-lg-6 col-md-6">
                            <video src={video} className="ipq-video" type="video/mp4" ref="ipqVideo" onScroll={ ()=> this.handleScroll() }/>
                            <div>
                                <div onClick={this.playVideo}>
                                <div className="cta-video-image__image">
                                    {this.state.isPlaying 
                                    ? 
                                    <div className="cta-video-image__icon">
                                    <i className="ion-ios-pause" />
                                    </div> 
                                    :
                                    <div className="cta-video-image__icon">
                                    <i className="ion-ios-play" />
                                </div>}
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default VideoCta;